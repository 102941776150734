import React from 'react';
import Hero from '../components/sections/Hero';




const Main = () => {

    return (
        <>
            <Hero className="illustration-section-01" />
        </>
    );
}

export default Main;